<template>
  <!--nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/ebook">ebook</router-link>
  </nav-->
  <router-view></router-view>
</template>

<style>
html {
  height: 100%;
}
body {
  height: 100%;
  padding: 0;
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  padding: 0;
  height: 100%;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>

<script>
import axios from 'axios'
export default {
  data() {
    return {}
  },
  created() {
    // this.getWxJssdkConf()
    //app.config.globalProperties.$wxapi = wxapi
  },
  methods: {
    async getWxJssdkConf() {
      axios.get('https://bzgh.macroc.cn/develop/wechatPublic/config', {
      // this.$axios.get('api/wechatPublic/code', {
        params: {}
      }).then(res => {
        console.log(res.data)
        this.$wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: res.data.appId, // 必填，公众号的唯一标识
          timestamp: String(res.data.timestamp), // 必填，生成签名的时间戳
          nonceStr: String(res.data.nonceStr), // 必填，生成签名的随机串
          signature: res.data.signature, // 必填，签名，见附录1
          jsApiList: [
            "scanQRCode",
            "getLocation",
          ], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        });
        this.$wx.ready(() => {
          this.$wx.checkJsApi({
            jsApiList: ["scanQRCode", "getLocation"], // 需要检测的JS接口列表，所有JS接口列表见附录2,
            success: function (res) {
              console.log("checkJsApi", res);
            },
          });
        });
        this.$wx.error((res) => {
          console.log("wx.error", res);
        });
      })
    }
  }
}


</script>

