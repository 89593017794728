import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'dayjs/locale/zh-cn';
import locale from 'element-plus/es/locale/lang/zh-cn';
import axios from 'axios'
import App from './App.vue'
import router from './router'
import wx from "weixin-js-sdk"
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import store from './store'

const app = createApp(App)
console.log(process.env.NODE_ENV)
app.use(store).use(router).use(ElementPlus, {locale}).mount('#app')
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.config.globalProperties.$axios = axios
//axios.defaults.baseURL = '/develop'
axios.defaults.baseURL = '/release'
app.config.globalProperties.$wx = wx
app.config.globalProperties.$wxapi = ''
